.toolbar-title {
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #fff;
  text-decoration: none;
}

.cursor-pointer {
  cursor: pointer;
}

.video-trailer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.container-trailer {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}